<template>
  <thead
    class="px-table-fixed-header px-table-header"
    data-testid="px-table-header"
  >
    <tr class="px-table-header-tr">
      <th
        v-if="showSelect && isAnyItemEnabled"
        rowspan="1"
        colspan="1"
        class="px-table-header-th"
        scope="row"
      >
        <label
          v-if="multipleSelect"
          class="px-checkbox px-table-checkbox-wrapper"
        >
          <span :class="`px-checkbox-content ${isSelectedAllSelf ? 'px-checkbox-checked' : ''}`">
            <input
              v-model="isSelectedAllSelf"
              type="checkbox"
              class="px-checkbox-input"
              value
              @change="onToggleSelectAll"
            >
            <span class="px-checkbox-inner" />
          </span>
          <span class="px-checkbox-label" />
        </label>
      </th>

      <th
        v-for="key in headerKeys"
        :key="key"
        rowspan="1"
        colspan="1"
        class="px-table-header-th"
        scope="row"
        :style="{ width: '200px' }"
      >
        {{ setHeaderTitle(key) }}
        <dl
          v-if="!headerConfig[key] || (headerConfig[key] && !headerConfig[key].notFilterable)"
          class="px-dropdown"
          @click="onToggleCurrentFilter(key)"
        >
          <dt class="px-dropdown-dt">
            <a>
              <span class="px-table-filter">
                <span
                  :class="`px-table-filter-icon ${getFilterStatusByKey(key) ? 'px-table-filter-icon-active' : ''}`"
                >
                  <i class="px-icon iconfont-vet icon-vet-filter" />
                </span>
              </span>
            </a>
          </dt>
        </dl>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'CMTableHeader',

  props: {
    showSelect: {
      type: Boolean,
      default: false
    },

    headerConfig: {
      type: Object,
      default: () => ({})
    },

    customTitle: {
      type: Array,
      default: () => ([])
    },

    isSelectedAll: {
      type: Boolean,
      default: false
    },

    filters: {
      type: Object,
      default: () => ({})
    },

    headerKeys: {
      type: Array,
      default: () => ([])
    },

    multipleSelect: {
      type: Boolean,
      default: true
    },

    isAnyItemEnabled: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isSelectedAllSelf: false
    }
  },

  watch: {
    isSelectedAll (val) {
      this.isSelectedAllSelf = val
    }
  },

  methods: {
    onToggleSelectAll () {
      this.$emit('onToggleSelectAll', this.isSelectedAllSelf)
    },

    setHeaderTitle (key) {
      if (this.customTitle.length) {
        const currentTitle = this.customTitle
          .find(customTitle => customTitle.value === key) || {}

        return this.$t(currentTitle.text) || key.toUpperCase()
      }

      return key.toUpperCase()
    },

    onToggleCurrentFilter (key) {
      this.$emit('onToggleCurrentFilter', key)
    },

    getFilterStatusByKey (key) {
      return this.filters[key]
        ? Object.values(this.filters[key]).some(Boolean)
        : false
    }
  }
}
</script>

<style scoped>
.px-table-header-tr {
  height: 40px;
}

.px-table-header-th {
  border-top: 0px;
  position: sticky;
  background-color: #1d1d1d;
  padding: 10px;
  font-weight: 500;
  color: #ffffffd9;
  text-align: center;
  top: 0px;
  z-index: 5;
}

.px-checkbox {
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.px-table-checkbox-wrapper {
  width: 25px;
  display: inline-block;
}

.px-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.px-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #abbacc;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}

.px-checkbox-filter {
  display: inline-block;
}

.px-checkbox .px-checkbox-checked .px-checkbox-inner {
  background-color: #f4c020;
  border-color: #f4c020;
}

.px-checkbox-inner:after {
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4px;
  top: 2px;
  display: table;
  width: 6px;
  height: 9px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6)
}

.px-checkbox .px-checkbox-checked .px-checkbox-inner:after {
  transform: rotate(45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.px-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #f4c020;
  content: "";
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
}

.px-dropdown {
  display: inline-table;
  margin: 0;
  max-width: 400px;
  width: max-content;
  max-height: 300px;
}

.px-dropdown-dt {
  margin: 0px;
  padding: 0px;
}

.px-dropdown-dt > a {
  width: 90px;
}

.px-dropdown a, .px-dropdown a:visited {
  color: #000;
  text-decoration: none;
  outline: none;
}

.px-table-filter {
  display: inline-block;
  position: relative;
  width: 0;
  height: 16px;
  cursor: pointer;
}

.px-table-filter-icon {
  color: #fff;
  position: absolute;
  top: 4px;
  left: 5px;
}

.px-table-filter-icon-active {
  color: #f4c020;
}

.px-icon {
  display: inline-block;
}

.px-checkbox-content {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: text-bottom;
}

.px-checkbox-label {
  margin: 0 6px 0 3px;
  width: 100%;
  color: #ffffffd9 !important;
}
</style>
